var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.loading ? _c("b-skeleton") : _vm._e(),
      !_vm.loading
        ? _c(
            "span",
            [
              _vm._l(_vm.firstParticipants, function (participant, index) {
                return _c(
                  "span",
                  { key: _vm.participantKey(index) },
                  [
                    index != 0 ? _c("span", [_vm._v(", ")]) : _vm._e(),
                    participant.user_uuid
                      ? _c("img", {
                          staticClass: "icon mr-1",
                          attrs: {
                            alt: _vm.$t("SEFOS_USER"),
                            src: "/img/message_type/message.png",
                          },
                        })
                      : _vm._e(),
                    !_vm.draft
                      ? _c("MessageStatus", {
                          attrs: { status: participant.status },
                        })
                      : _vm._e(),
                    _vm._v(
                      " " + _vm._s(_vm.getName(participant)) + "\n        "
                    ),
                  ],
                  1
                )
              }),
              _vm.totalCount >= 3
                ? _c("span", [
                    _vm._v(", .. (+" + _vm._s(_vm.totalNotInFirstCount) + ")"),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }