var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status != undefined
    ? _c("span", [
        _c(
          "span",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover",
                modifiers: { hover: true },
              },
            ],
            attrs: { title: _vm.MessageDescription },
          },
          [_c("i", { class: _vm.icon })]
        ),
        _vm.showText
          ? _c("span", [_vm._v(" " + _vm._s(_vm.MessageDescription))])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }